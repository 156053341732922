
export default {
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    pageLink() {
      return `${this.blok.baseRoute}${this.blok.link?.cachedUrl?.replaceAll(
        "/",
        "__"
      )}`
    },
  },
}
